import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      You have attempted a route that doesn&#39;t exist on the site. Please
      return&nbsp;
      <Link
        to="/"
        style={{
          textDecoration: `none`,
        }}
      >
        home
      </Link>
      .
    </p>
  </Layout>
)

export default NotFoundPage
